<template lang="pug">
v-sheet.flex-column.d-flex.justify-center.align-center.pa-4(
  color="transparent",
  height="100%",
  width="100%"
)
  a.logo.mb-4(href="https://www.mrxweb.com.br")
    v-img(width="240px", src="/img/logo.svg")
  v-sheet.mt-1(color="transparent", max-width="100%", width="400")
    div
      .text-center.font-weight-bold.white--text.text-body-1 Digite o texto aqui
      v-text-field(
        v-model="text",
        placeholder="mrxweb.com.br",
        max-width="100%",
        width="400px",
        prepend-inner-icon="fas fa-qrcode",
        solo
      )
  v-btn.elevation-3(
    @click="download",
    color="secondary",
    title="Baixar",
    fab,
    small,
    fixed,
    right,
    bottom
  )
    v-icon(small) fas fa-download
  v-btn.mr-12.elevation-3(
    v-if="canShare",
    @click="share",
    color="secondary",
    title="Compartilhar",
    fab,
    small,
    fixed,
    right,
    bottom
  )
    v-icon(small) fas fa-share
  div(v-html="qrcode")
  .font-weight-bold MRX Websites ® 2020. Todos os direitos reservados.
</template>

<script>
import QRCode from "qrcode";
import Tooltip from "@/components/tools/Tooltip";

export default {
  data: () => ({
    text: null,
    qrcode: "",
  }),
  computed: {
    canShare() {
      return (
        navigator.canShare &&
        navigator.canShare({ files: [this.file] }) &&
        false
      );
    },
    file() {
      return new File([this.qrcode], "qrcode.svg", {
        type: "image/svg+xml",
      });
    },
  },
  methods: {
    generate(text) {
      QRCode.toString(text, { type: "svg" }, (err, qrcode) => {
        if (err) throw err;
        this.qrcode = qrcode;
      });
    },
    share() {
      navigator.share({
        title: this.text || "mrxweb.com.br",
        files: [this.file],
      });
    },
    download() {
      const reader = new FileReader();

      reader.onloadend = () => {
        let a = document.getElementById("download");

        if (!a) {
          a = document.createElement("a");
          a.id = "download";
          a.download = "qrcode";
        }

        a.href = reader.result;
        a.click();
      };

      reader.readAsDataURL(this.file);
    },
  },
  created() {
    this.generate("mrxweb.com.br");
  },
  watch: {
    text(v) {
      if (v) this.generate(v);
      else this.generate("mrxweb.com.br");
    },
  },
  components: {
    Tooltip,
  },
};
</script>

<style>
.logo {
  filter: brightness(0) invert(1);
}
.logo > div {
  transition: 0.2s;
}
.logo > div:hover {
  transform: scale(1.05);
}

svg {
  width: 600px;
  max-width: 100%;
}
</style>
