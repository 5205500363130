import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

const server = "https://www.promocao.mrxweb.com.br";

Vue.config.productionTip = false;

Vue.mixin({
  data: () => ({
    server,
    files: server
  })
});

new Vue({
  router,
  vuetify,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
